import { getLocalData } from "../utils/helper";
const CURRENT_HOME = getLocalData("HOME");
export const ROUTES_URL = {
  HOME: CURRENT_HOME ? ("/sp/" + CURRENT_HOME).toString() : "/",
  LOGIN: "/login",
  REGISTER: "/register",
};


//----------------------backend-api url-------------------//
  // export const API_GATEWAY = "http://localhost:8080"  
// export const API_GATEWAY = "https://cc-api-phase2.herokuapp.com"; //production mode  - HEROKU
// export const API_GATEWAY = "https://theclovercarte.com"; //production mode  - AWS
// export const API_GATEWAY = "https://makeinindiaopen.in"; //development mode  - AWS
export const   API_GATEWAY = "https://server-cc-lm3u6.ondigitalocean.app";




//----------------------Websocket url-------------------//
// export const WEBSOCKET_GATEWAY = "ws://localhost:8080/WebSocket";
// export const WEBSOCKET_GATEWAY = "wss://cc-api-phase2.herokuapp.com/Websocket"; 
// export const WEBSOCKET_GATEWAY = "wss://theclovercarte.com";
// export const WEBSOCKET_GATEWAY = "wss://makeinindiaopen.in";
export const WEBSOCKET_GATEWAY = "wss://server-cc-lm3u6.ondigitalocean.app/Websocket";


export const API_URL = {
  ORDER: `${API_GATEWAY}/order`,
  GET_ORDERS: `${API_GATEWAY}/order/machine`,
  GET_ORDERS_MACHINE: `${API_GATEWAY}/order/machine`,
  GET_PREVIOUS_ORDER: `${API_GATEWAY}/order/previous`,
  GET_ORDER_BY_ID: `${API_GATEWAY}/order/getbyid`,
  GET_ORDER_BY_NUMBER: `${API_GATEWAY}/order/get-by-no`,
  ORDER_COMPLETE: `${API_GATEWAY}/is-order-complete`,
  THANKYOU: `${API_GATEWAY}/thank-you`,

  GET_TOKEN_LIST: `${API_GATEWAY}/order/get-token-list`,

  GET_MATERIAL: `${API_GATEWAY}/material/get`,
  GET_SUPPLIER: `${API_GATEWAY}/supplier/get`,
  GET_CATEGORY: `${API_GATEWAY}/category/get`,
  GET_CATEGORY_BY_POINT: `${API_GATEWAY}/category/sp`,
  GET_PRODUCT: `${API_GATEWAY}/products/get`,
  GET_ARTICLE: `${API_GATEWAY}/articles/getAll`,
  GET_PRODUCT_BY_POINT: `${API_GATEWAY}/products/point`,
  GET_MACHINE_BY_POINT: `${API_GATEWAY}/machine/get-my-machines-by-point`,
  GET_MACHINE_STATE: `${API_GATEWAY}/machine-status`,
  UPDATE_MACHINE_STATE: `${API_GATEWAY}/machine/updatestatus`,
  GET_MACHINE_SOCKET_STATE: `${API_GATEWAY}/machine/getbycode`,

  GET_SALES_POINT_BY_ID: `${API_GATEWAY}/sales-point/getbypoint`,

  GET_GUEST_SESSION: `${API_GATEWAY}/user/guest-session`,

  POST_REVIEW: `${API_GATEWAY}/review/create`,
  POST_COUSTOM_ORDER: `${API_GATEWAY}/order-by-coustom`,
  UPDATE_REVIEW: `${API_GATEWAY}/review/update`,

  GET_RECIPE_BY_MIN: `${API_GATEWAY}/recipe/get-min`,
  GET_RECIPE_BY_POINT: `${API_GATEWAY}/recipe/get-article-point`,

  USER_REGISTER: `${API_GATEWAY}/user/register`,
  USER_GOOGLE_SIGNUP: `${API_GATEWAY}/user/register`,
  USER_LOGIN: `${API_GATEWAY}/user/login`,
  USER_LOGOUT: `${API_GATEWAY}/user/logout`,
  USER_GOOGLE_SIGN_IN: `${API_GATEWAY}/user/login`,
  SEND_OTP: `${API_GATEWAY}/user/send-otp`,
  VERIFY_OTP: `${API_GATEWAY}/user/verify-otp`,
  FORGOT_PASSWORD: `${API_GATEWAY}/user/forgot-password`,
  RESET_PASSWORD: `${API_GATEWAY}/user/reset-password`,
  VERIFY_RESET_TOKEN: `${API_GATEWAY}/user/verify-reset-token`,

  GET_WALLET: `${API_GATEWAY}/wallet/get`,
  GET_WALLET_TRANSACTIONS: `${API_GATEWAY}/wallet/transaction`,
  RECHARGE_WALLET: `${API_GATEWAY}/wallet/recharge`,
  ORDER_BY_WALLET: `${API_GATEWAY}/order-by-wallet`,
  ORDER_BY_UPI: `${API_GATEWAY}/qrInit`,
  WALLET_RECHARGE_COMPLETE: `${API_GATEWAY}/wallet/recharge-complete`,

  VERIFY_COUPON: `${API_GATEWAY}/coupon/verify`,


};



import { useEffect, useRef, useState } from "react";

import socketIOClient from 'socket.io-client';
import "./help.css";
// library
import {
  Grid,
  Container,
  Typography,
  Card,
  CardContent,
  CardMedia,
  Box,
  Fab,
  Tooltip,
  IconButton,
  Menu,
  MenuItem,
  Stack,
  Select,
  FormControl,
  FormLabel,
  RadioGroup,
  Radio,
  FormControlLabel,
  Paper,
} from "@mui/material";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import toastr from "toastr";
import PlaceIcon from "@mui/icons-material/Place";
import Page404 from "../pages/Page404/Page404.page";

import {
  calculatePrice,
  container_info,
  getMachineCode,
  getMaterialCode,
  getMaterialName,
  setLocalData,
  showCustomizeButton,
  slot_info,
  totalCharges,
  totalChargesInProduct,
  totalCustomizeCharges,
} from "../utils/helper.js";
// components
import Page from "../components/Page.js";
import { Outlet } from "react-router-dom";
// utils
import { getMethod, postMethod } from "../utils/api";
// constants
import { API_GATEWAY, API_URL } from "../constants/url.constant";
import { API_STATUS_CODE, MESSAGE } from "../constants/content.constant";
//context/store/state
import { useCart } from "../contexts/cart/use-cart";
import CategoryScrollbar from "../components/Categorgy/CategoryScrollbar.js";
import CheckoutBtnBottom from "../features/cart/CheckoutBtnBottom.jsx";

import { MHidden } from "../components/@material-extend";
import { makeStyles } from "@material-ui/core";
import { useParams } from "react-router-dom";
import CheckoutBtn from "../features/cart/CheckoutBtn";
import Machine_ERR_page from "./Page404/Machine_ERR_page";
import { useDispatch } from "react-redux";
import { actionMachine } from "../store/slices/machine.slice";
import useMachineState from "../utils/CheckMachineState";
import { getWebSocketQuantity } from "../components/Socket/WebSocketClient";
import WarningPopup from "../components/Machine/WarningPopup";
import MachineStausPopup from "../components/Machine/MachineStausPopup";
import OrderCustomizationPopup from "../components/OrderCustomizePopup";

const SORT_ITEMS_OPTIONS = [
  { value: "1", label: "Price High to Low" },
  { value: "2", label: "Price Low to High" },
  { value: "3", label: "Most Popular" },
  // { value: "4", label: "Reset All" },
];

// ----------------------------------------------------------------------

const useStyles = makeStyles({
  root: {
    "&:hover": {
      backgroundColor: "black",
    },
  },
  root2: {
    "&:hover": {
      backgroundColor: "green",
    },
  },
});

export default function DashboardApp() {
  const {
    addItem,
    removeItem,
    getItem,
    getItemQuantity,
    isInCart,
    cartItemsCount,
  } = useCart();
  const dispatch = useDispatch();
  const { id } = useParams();
  const machineState1 = useMachineState();
  
  const classes = useStyles();
  const [product, setProduct] = useState([]);
  const [materialData, setMaterialData] = useState([]);
  const [maxItemLimit, setMaxItemLimit] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const [filteredProduct, setFilteredProduct] = useState([]);
  const [selectedItem, setSelectedItem] = useState();
  const [salesPoint, setSalesPoint] = useState([]);
  const [machineData, setMachineData] = useState();
  const [supplierData, setSupplierData] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [stateobj, setStateobj] = useState({});
  const [orderedQty, setOrderedQty] = useState();
  const [isDetailed, setIsDetailed] = useState(false);
  const open = Boolean(anchorEl);
  const [error, setError] = useState(false);
  const [varient, setVarient] = useState("All");
  const [variants, setVariant] = useState([]);
  const [selectedRecipe, setSelectedRecipe] = useState();
  const [selectedOptions, setSelectedOptions] = useState("");
  const [recipeData, setRecipeData] = useState(null);
  const [detailPrice, setDetailprice] = useState();
  const [quantity, setQuantity] = useState("");
  const prevMinIdsRef = useRef();
  //variant should be items based array
  useEffect(() => {
    try {
      fetchProductsByPoints();
      fetchSalesPointById();
      fetchSupplier();
    } catch (error) {
      console.log(error);
    }
  }, []);
  const handleClickFilterBtn = (event, item) => {
    const BY = item?.value;
    filterProductBy(BY);
    setAnchorEl(event.currentTarget);
  };
  const filterProductBy = (filterBy) => {
    switch (filterBy) {
      case "1":
        filteredProduct.sort((a, b) => Number(b.price) - Number(a.price));
        break;
      case "2":
        filteredProduct.sort((a, b) => Number(a.price) - Number(b.price));
        break;
      case "4":
        setFilteredProduct(product);
        break;
      default:
        setFilteredProduct(product);
    }
  };
  useEffect(() => {}, [filteredProduct]);

  useEffect(() => {}, [error]);

  useEffect(() => {
    setLocalData("HOME", id.toUpperCase());
  }, []);
  useEffect(() => {
    if (product && product.length > 0) {
      fetchRecipes();
    }
  }, [product]);
  useEffect(() => {
    try {
      fetchGuestSession();
    } catch (error) {
      console.log(error);
    }
  }, []);

  useEffect(() => {
    setMaxItemLimit(cartItemsCount() < 4 ? false : true);
  }, [cartItemsCount]);
  useEffect(() => {
    if (recipeData?.length > 0) {
      mergeRecipeAndProduct();
    }
  }, [recipeData]);

  const handleCloseFilterBtn = () => {
    setAnchorEl(null);
  };

  const sortProducts = (name) => {
    if (name === "All") {
      setFilteredProduct(product);
    }
    if (name && name !== "All") {
      let filterData = product?.filter((f) => f.category === name);
      //  filteredProduct=  filterData
      setFilteredProduct(filterData);
    }
  };

  const MaxItemAlert = () => {
    toastr.success(MESSAGE.MAX_ITEM_LIMIT_ALERT);
    return false;
  };

  const setSelectedVariant = (vari, index) => {
    const oldVariants = variants;
    const existingItemIndex = oldVariants.findIndex(
      (c) => c.itemIndex == index
    );

    if (existingItemIndex > -1) {
      console.log("hello", existingItemIndex);

      let newState = oldVariants;
      newState[existingItemIndex].variantIndex = vari;
      setVariant(newState);
    } else {
      let oldArr = variants;

      let newVariant = { variantIndex: vari, itemIndex: index };

      oldArr.push(newVariant);
      console.log("else", oldArr);
      setVariant(oldArr);
    }
    //indexing of items in state
    setVarient(vari);
  };

  const getPriceIndex = (itemIndex) => {
    const existingItemIndex = variants?.findIndex(
      (variant) => variant.itemIndex === itemIndex
    );

    if (existingItemIndex > -1) {
      return variants[existingItemIndex].variantIndex;
    } else {
      return 0;
    }
  };
  const sendSelectedItemToCart = (item, index, type, recipe) => {
    if (type === "customize" && recipe !== undefined) {
      const data = JSON.parse(recipe);
      item.price = data?.mrp;
      item.recipe = data?.code;
      item.recipes = [data];
      addItem(item);
      // console.log(item)
    }
    if(recipe!=="no"&&type==='detailedCustomize') {
      const data = JSON.parse(recipe);
      item.price = data?.mrp;
      item.recipe = data?.code;
      item.recipes = [data];
      addItem(item);
      // console.log(item)
    }

    if (!type) {
      if(orderedQty){
        const orderId = orderedQty?orderedQty.split('/')[1]:''
        const qty = orderedQty?orderedQty.split('/')[0]:''
       if(item._id === orderId) {
         const matchedRecipe = item.defaultRecipe.find((i) => i.quantity === qty);
      item.price = matchedRecipe?.mrp;
      item.recipe = matchedRecipe?.code;
      addItem(item);
      // console.log(item)
      }
      }else{
        item.price = item?.mrp||item?.product_price;
        item.recipe = item?.code;
        addItem(item);
        // console.log(item)

      }
    }
  };

  const removeSelectedItemFromCart = (item, index, type, recipe) => {
    if (type === "customize" && recipe !== "") {
      const data = JSON.parse(recipe);
      item.price = data?.mrp;
      item.recipe = data?.code;
      item.recipes = [data];
      removeItem(item);
      // console.log(item)
    }
    if (selectedOptions !== "") {
      item.recipe = selectedOptions;
      item.price = detailPrice;
      removeItem(item);
      // console.log(item)
    }

    if (!type) {
      item.price = item?.mrp;
      item.recipe = item?.code;
      removeItem(item);
      // console.log(item)
    }
  };

  const fetchGuestSession = async () => {
    try {
      // setLoading(true);
      const response = await getMethod(API_URL.GET_GUEST_SESSION, false, true);
      if (response?.status === API_STATUS_CODE.SUCCESS) {
      }
      // setLoading(false);
    } catch (error) {
      console.log(error);
      // setLoading(false);
    }
  };
  const fetchProductsByPoints = async () => {
    try {
      // setLoading(true);
      const response = await getMethod(
        API_URL.GET_PRODUCT_BY_POINT + "/" + id.toUpperCase(),
        false,
        true
      );
      const response1 = await getMethod(API_URL.GET_MATERIAL, false, false);
      if (response?.status === API_STATUS_CODE.SUCCESS) {
        setProduct(response?.data?.data);
        setMaterialData(response1?.data?.data);
        setFilteredProduct(response?.data?.data);
      }
      // setLoading(false);
    } catch (error) {
      console.log(error);
      // setLoading(false);
    }
  };

  const fetchSupplier = async () => {
    try {
      // setLoading(true);
      const response = await getMethod(API_URL.GET_SUPPLIER, false, true);
      if (response?.status === API_STATUS_CODE.SUCCESS) {
        setSupplierData(response?.data?.data);
      }
      // setLoading(false);
    } catch (error) {
      console.log(error);
      // setLoading(false);
    }
  };
  const fetchSalesPointById = async () => {
    try {
      // setLoading(true);
      const response = await getMethod(
        API_URL.GET_SALES_POINT_BY_ID + "/" + id.toUpperCase(),
        false,
        true
      );
      if (response?.status === API_STATUS_CODE.SUCCESS) {
        setSalesPoint(response?.data?.data);
        const responseMach = await getMethod(API_URL.GET_MACHINE_BY_POINT +'/'+response?.data?.data?.sp_id,false,false)
        if (responseMach?.status === API_STATUS_CODE.SUCCESS) {
          setMachineData(responseMach?.data?.data[0]);
          setLocalData('company_id',responseMach?.data?.data[0]?.company_id)
          // console.log(responseMach?.data?.data);
        }else{
          setError(true);
        }

      } else {
        setError(true);
      }
      // setLoading(false);
    } catch (error) {
      console.log("error", error);
      // setLoading(false);
    }
  };
  const fetchRecipes = async () => {
    try {
      const response = await getMethod(API_URL.GET_RECIPE_BY_POINT + '/' + id)
      if(response.status === API_STATUS_CODE.SUCCESS){
        const flattenedRecipeData = response?.data?.data
      const finalData = flattenedRecipeData.filter(
        (recipe) => recipe.assigned !== "false"
      );
      setRecipeData(finalData);}
      // alert(recipeData.length)
    } catch (error) {
      console.error(error);
    }
  };

  const callback = (name) => {
    sortProducts(name);
  };

  useEffect(() => {
    findMostRepeatingIdno();
  }, [product]);

  const customizeOptions = (item) => {
    const recipe = recipeData.filter((i) => i.article === item._id).flat();
    setSelectedItem({ ...item, customize: recipe });
    setQuantity(recipe[0]?.quantityArray[0]?.quantityName);
  };
  const setRecipeFn = (value) => {
    setSelectedOptions("");
    const data = value ? JSON.parse(value) : {};
    const selectedOption = data.options;
    const fvalue = selectedOption?.filter((i) => i.quantity === quantity)[0];
    setSelectedRecipe(JSON.stringify(fvalue));
  };
  const findMostRepeatingIdno = () => {
    const machineArray = [];
    if (product.length > 0) {
      product.forEach((item) => {
        if (item.machine && item.machine.length > 0) {
          item.machine.forEach((machine) => {
            machineArray.push({ idno: machine.idno, codeno: machine.codeno });
          });
        }
      });

      const occurrences = machineArray?.reduce((acc, item) => {
        acc[item.idno] = (acc[item.idno] || 0) + 1;
        return acc;
      }, {});

      const mostRepeatingIdno = Object.keys(occurrences)?.reduce((a, b) =>
        occurrences[a] > occurrences[b] ? a : b
      );

      const result = machineArray.find(
        (item) => item.idno === mostRepeatingIdno
      );
      // console.log(result);
      dispatch(actionMachine(result))
    }
  };
  const mergeRecipeAndProduct = () => {
    const mergedData = filteredProduct
      .map((product) => {
        const recipe = recipeData.find(
          (recipe) => recipe.article === product._id
        );
        if (recipe) {
          const code = recipe?.defaultRecipe[0].code;
          const mrp = recipe?.defaultRecipe[0].mrp;
          const available = recipe?.available;
          const quantityArray = recipe?.quantityArray;
          const defaultRecipe = recipe?.defaultRecipe;
          return { ...product, code: code, mrp: +mrp, available: available,quantityArray: quantityArray, defaultRecipe: defaultRecipe};
        } else {
          return null;
        }
      })
      .filter((product) => product !== null);
    const mergedData1 = product
      .map((product) => {
        const recipe = recipeData.find(
          (recipe) => recipe.article === product._id
        );
        if (recipe) {
          const code = recipe?.defaultRecipe[0].code;
          const mrp = recipe?.defaultRecipe[0].mrp;
          const available = recipe?.available;
          const quantityArray = recipe?.quantityArray;
          const defaultRecipe = recipe?.defaultRecipe;
          return { ...product, code: code, mrp: +mrp, available: available,quantityArray: quantityArray, defaultRecipe: defaultRecipe };
        } else {
          return null;
        }
      })
      .filter((product) => product !== null);
    setFilteredProduct(mergedData);
    setProduct(mergedData1);
  };
  const [detailedRecipeArr,setDetailedRecipeArr]=useState([])
  const handleCreateRecipe = (value, materialCode) => {
    const materialId = value.slice(0, 2);

    const updatedRecipe = detailedRecipeArr.filter(item => !item.startsWith(materialId));
    updatedRecipe.push(value);
    setDetailedRecipeArr(updatedRecipe);
    setIsValid(false)
  };
  const sendDetailedRecipe = (materialLength)=>{
    if(materialLength===detailedRecipeArr?.length){
      const recipe = detailedRecipeArr.join("+")
      return JSON.stringify({mrp:calculatePrice(
        recipe,
        slot_info(selectedItem),
        container_info(selectedItem),
        supplierData
      ) +
        totalCharges(selectedItem) +
        totalCustomizeCharges(selectedItem),
        code:recipe})
    }else {
       setIsValid(true)
       return 'no'
    }
  }
  const closePopup = () => {
    setSelectedItem(null);
  };
  const trayProducts = machineData?.traysInfo?.reduce((accumulator, tray) => {
    if (Array.isArray(tray.coilsInfo)) {
      const validCoils = tray.coilsInfo
          .filter(coil => coil.product_id)
          .map(coil => ({
              ...coil,
              machine: {
                  codeno: machineData.codeno,
                  idno: machineData.machine_code
              }
          }));
      return accumulator.concat(validCoils);
  }
  return accumulator;

}, []);

  return (
    <>
      <Outlet />
      <Page title="Clover Carte | Home">
        <WarningPopup machineCondition={machineState1} />
         <MachineStausPopup data={false} />
     
          <>
            {!error ? (
              <Container>
              
                <Container
                  maxWidth="xl"
                  sx={{ flex: "1 0 auto", height: "100vh" }}
                >
                  <Card sx={{ width: "80%", margin: "auto" }}>
                    <CategoryScrollbar
                      spid={id.toUpperCase()}
                      onClick={callback}
                    />
                  </Card>
                  <Box>
                    <Grid
                      container
                      spacing={2}
                      sx={{ mt: 2 }}
                      justifyContent="space-between"
                    >
                      <Grid item xs={10}>
                        <CardContent sx={{ p: 1 }}>
                          <Typography
                            component="div"
                            variant="subtitle2"
                            color="text.secondary"
                          >
                            <PlaceIcon sx={{ size: "5px" }} />{" "}
                            {salesPoint?.city},
                            {/* <a href={`http://199.192.25.116:3088/{"codeno":"5", "idno":"cf031111", "dispmsg":"testapi","sno":"117","msg":"te4050+ml4030+sg4012"}`} >Make Order</a> */}
                          </Typography>
                          <Typography component="div" variant="h6">
                            Welcome at, {salesPoint?.name}
                          </Typography>
                         
                        </CardContent>
                      </Grid>

                      <Grid item xs={2} container justifyContent="flex-end">
                        <Tooltip title="Filter Items">
                          <IconButton
                            onClick={handleClickFilterBtn}
                            size="small"
                            sx={{ ml: 2 }}
                            aria-controls={open ? "account-menu" : undefined}
                            aria-haspopup="true"
                            aria-expanded={open ? "true" : undefined}
                          >
                            Filter <FilterAltIcon />
                          </IconButton>
                        </Tooltip>
                      </Grid>
                    </Grid>
                  </Box>
                  {machineData?.min_info?.type==='Tray'?(
                  <Grid container spacing={0} sx={{ pt: 1 }}>
                    {/* below is old product box  */}
                    {trayProducts?.map((item, index) => {
                      return (
                        <Grid item xs={12} sm={6} md={4} key={index}>
                          <Card className="item_container rounded-3 m-1">
                            <div className="d-flex">
                              <CardMedia
                                component="img"
                                sx={{
                                  width: 90,
                                  height: 115,
                                  m: 1,
                                  filter:
                                    item?.product_id
                                      ? "grayscale(0%)"
                                      : "grayscale(100%)",
                                }}
                                src={item?.product_img}
                                alt={item?.title}
                              />

                              <Box
                                sx={{
                                  display: "flex",
                                  flexDirection: "column",
                                }}
                              >
                                <CardContent sx={{ p: 1 }}>
                                 
                                  <Typography component="div" variant="h6">
                                    {item?.product_name}
                                  </Typography>

                                  <Stack direction="row" spacing={6}>
                                    <Typography
                                      variant="subtitle2"
                                      color="text.secondary"
                                      component="div"
                                      className="text-nowrap"
                                    >
                                      Rs. {item?.product_price || 0}
                                    </Typography>
                                  </Stack>
                                </CardContent>
                                {item?.serve_status? (
                                  <Box className="d-flex float-end pt-3">
                                  
                                    {isInCart && !isInCart(item.id) ? (
                                      <Box
                                        sx={{
                                          display: "flex",
                                          alignItems: "center",
                                          pl: 1,
                                          pb: 1,
                                        }}
                                      >
                                        <Fab
                                          disabled={
                                            item.product_id
                                              ? false
                                              : true
                                          }
                                          onClick={() =>
                                            // cartItemsCount() === 3
                                            //   ? MaxItemAlert()
                                            //   :
                                            sendSelectedItemToCart(item, index)
                                          }
                                          variant="extended"
                                          size="small"
                                          className={classes.root2}
                                          sx={{
                                            backgroundColor: "green",
                                            color: "white",
                                            fontSize: 12,
                                            width: 90,
                                            marginRight: 1,
                                          }}
                                          aria-label="add"
                                        >
                                          Add to Cart 
                                        </Fab>
                                      </Box>
                                    ) : (
                                      <Box
                                        sx={{
                                          display: "flex",
                                          alignItems: "center",
                                          pl: 1,
                                          pb: 1,
                                        }}
                                      >
                                        <Fab
                                          variant="extended"
                                          size="small"
                                          className={classes.root}
                                          sx={{
                                            backgroundColor: "black",
                                            color: "white",
                                          }}
                                          aria-label="addDelete"
                                        >
                                          <RemoveCircleOutlineIcon
                                            onClick={() =>
                                              removeSelectedItemFromCart(
                                                item,
                                                index
                                              )
                                            }
                                          />
                                          <Typography
                                            sx={{ padding: "10px" }}
                                            variant="subtitle1"
                                            color="white"
                                            component="div"
                                          >
                                            {getItemQuantity(item.id)}
                                          </Typography>

                                          <AddCircleOutlineIcon
                                            onClick={() =>
                                              // cartItemsCount() === 3
                                              //   ? MaxItemAlert()
                                              //   :
                                              sendSelectedItemToCart(
                                                item,
                                                index
                                              )
                                            }
                                          />
                                        </Fab>
                                      </Box>
                                    )}
                                  </Box>
                                ) : (
                                  <Typography
                                    sx={{ fontSize: 15 }}
                                    color="text.secondary"
                                  >
                                    Out Of Stock
                                  </Typography>
                                )}
                              </Box>
                            </div>
                            {/* <div className="d-flex float-end px-2">
                         
                        </div> */}
                          </Card>
                        </Grid>
                      );
                    })}
                  </Grid>)
                :
                (
                  <Grid container spacing={0} sx={{ pt: 1 }}>
                    {/* below is old product box  */}
                    {filteredProduct?.map((item, index) => {
                      return (
                        <Grid item xs={12} sm={6} md={4} key={index}>
                          <Card className="item_container rounded-3 m-1">
                            <div className="d-flex">
                              <CardMedia
                                component="img"
                                sx={{
                                  width: 90,
                                  height: 115,
                                  m: 1,
                                  filter:
                                    item?.available === "yes"
                                      ? "grayscale(0%)"
                                      : "grayscale(100%)",
                                }}
                                src={item?.images?.url}
                                alt={item?.title}
                              />

                              <Box
                                sx={{
                                  display: "flex",
                                  flexDirection: "column",
                                }}
                              >
                                <CardContent sx={{ p: 1 }}>
                                 
                                  <Typography component="div" variant="h6">
                                    {item?.title}
                                  </Typography>
                                  {/* <Typography
                                component="div"
                                variant="subtitle2"
                                color="text.secondary"
                              >
                                {item?.description}
                              </Typography> */}

                                  <Stack direction="row" spacing={6}>
                                    <Typography
                                      variant="subtitle2"
                                      color="text.secondary"
                                      component="div"
                                      className="text-nowrap"
                                    >
                                      Rs. {item?.mrp || 0}
                                    </Typography>
                                    <select onChange={(e)=>setOrderedQty(e.target.value +'/'+item._id)} className="form-select" style={{height:30,fontSize:12,width:100}} >                                      
                                      {item?.defaultRecipe?.map((qty, index) => (
                                        <option key={index} value={qty?.quantity}>{qty?.quantity}</option>
                                      ))}
                                    </select>
                                  </Stack>
                                </CardContent>
                                {item?.available === "yes" ? (
                                  <Box className="d-flex float-end pt-3">
                                    {showCustomizeButton(
                                      recipeData,
                                      item?._id
                                    ) && (
                                      <Box
                                        sx={{
                                          display: "flex",
                                          alignItems: "center",
                                        }}
                                      >
                                        <button
                                          onClick={() => {
                                            customizeOptions(item);
                                          }}
                                          className="btn btn-secondary btn-sm mb-2 rounded-5"
                                          style={{ fontSize: 12, height: 34 }}
                                        >
                                          <b>Customize</b>
                                        </button>
                                      </Box>
                                    )}
                                    {isInCart && !isInCart(item._id) ? (
                                      <Box
                                        sx={{
                                          display: "flex",
                                          alignItems: "center",
                                          pl: 1,
                                          pb: 1,
                                        }}
                                      >
                                        <Fab
                                          disabled={
                                            item.available === "yes"
                                              ? false
                                              : true
                                          }
                                          onClick={() =>
                                            // cartItemsCount() === 3
                                            //   ? MaxItemAlert()
                                            //   :
                                            sendSelectedItemToCart(item, index)
                                          }
                                          variant="extended"
                                          size="small"
                                          className={classes.root2}
                                          sx={{
                                            backgroundColor: "green",
                                            color: "white",
                                            fontSize: 12,
                                            width: 90,
                                            marginRight: 1,
                                          }}
                                          aria-label="add"
                                        >
                                          Add to Cart 
                                        </Fab>
                                      </Box>
                                    ) : (
                                      <Box
                                        sx={{
                                          display: "flex",
                                          alignItems: "center",
                                          pl: 1,
                                          pb: 1,
                                        }}
                                      >
                                        <Fab
                                          variant="extended"
                                          size="small"
                                          className={classes.root}
                                          sx={{
                                            backgroundColor: "black",
                                            color: "white",
                                          }}
                                          aria-label="addDelete"
                                        >
                                          <RemoveCircleOutlineIcon
                                            onClick={() =>
                                              removeSelectedItemFromCart(
                                                item,
                                                index
                                              )
                                            }
                                          />
                                          <Typography
                                            sx={{ padding: "10px" }}
                                            variant="subtitle1"
                                            color="white"
                                            component="div"
                                          >
                                            {getItemQuantity(item._id)}
                                          </Typography>

                                          <AddCircleOutlineIcon
                                            onClick={() =>
                                              // cartItemsCount() === 3
                                              //   ? MaxItemAlert()
                                              //   :
                                              sendSelectedItemToCart(
                                                item,
                                                index
                                              )
                                            }
                                          />
                                        </Fab>
                                      </Box>
                                    )}
                                  </Box>
                                ) : (
                                  <Typography
                                    sx={{ fontSize: 15 }}
                                    color="text.secondary"
                                  >
                                    Out Of Stock
                                  </Typography>
                                )}
                              </Box>
                            </div>
                            {/* <div className="d-flex float-end px-2">
                         
                        </div> */}
                          </Card>
                        </Grid>
                      );
                    })}
                  </Grid>
                )}
                </Container>
                <MHidden width="smUp">
                  <CheckoutBtnBottom />
                </MHidden>
                <Menu
                  anchorEl={anchorEl}
                  id="account-menu"
                  open={open}
                  onClose={handleCloseFilterBtn}
                  onClick={handleCloseFilterBtn}
                  PaperProps={{
                    elevation: 0,
                    sx: {
                      overflow: "visible",
                      filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                      mt: 1.5,
                      "& .MuiAvatar-root": {
                        width: 32,
                        height: 32,
                        ml: -0.5,
                        mr: 1,
                      },
                      "&:before": {
                        content: '""',
                        display: "block",
                        position: "absolute",
                        top: 0,
                        right: 14,
                        width: 10,
                        height: 10,
                        bgcolor: "background.paper",
                        transform: "translateY(-50%) rotate(45deg)",
                        zIndex: 0,
                      },
                    },
                  }}
                  transformOrigin={{ horizontal: "right", vertical: "top" }}
                  anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                >
                  {SORT_ITEMS_OPTIONS.map((item) => {
                    return (
                      <MenuItem
                        key={item.value}
                        value={item.label}
                        onClick={(event) => handleClickFilterBtn(event, item)}
                      >
                        {item.label}
                      </MenuItem>
                    );
                  })}
                </Menu>
              </Container>
            ) : (
              <Box>
                <Page404 />
              </Box>
            )}
          </>
       
      </Page>
      <OrderCustomizationPopup
        isOpen={!!selectedItem}
        onClose={closePopup}
        selectedItem={selectedItem}
        isDetailed={isDetailed}
        setIsDetailed={setIsDetailed}
        setDetailedRecipeArr={setDetailedRecipeArr}
        quantity={quantity}
        setQuantity={setQuantity}
        setSelectedRecipe={setSelectedRecipe}
        selectedRecipe={selectedRecipe}
        setRecipeFn={setRecipeFn}
        handleCreateRecipe={handleCreateRecipe}
        materialData={materialData}
        detailedRecipeArr={detailedRecipeArr}
        isValid={isValid}
        getMaterialName={getMaterialName}
        isInCart={isInCart}
        sendSelectedItemToCart={sendSelectedItemToCart}
        removeSelectedItemFromCart={removeSelectedItemFromCart}
        getItemQuantity={getItemQuantity}
        classes={classes}
        sendDetailedRecipe={sendDetailedRecipe}
      />
    </>
  );
}

import React, { useState, useEffect } from 'react'
import { Outlet } from 'react-router-dom'
import Page from '../components/Page'
import { API_URL, WEBSOCKET_GATEWAY } from '../constants/url.constant'
import { getMethod, postMethod } from '../utils/api'
import { API_STATUS_CODE } from '../constants/content.constant'
import { Typography } from '@mui/material'

function CustomOrder() {
    const [formState, setFormState] = useState({
        codeno: '',
        recipe: '',
        orderno: ''
    })
    const [statusList, setStatusList] = useState([])

    const handleChange = (e) => {
        setFormState({ ...formState, [e.target.name]: e.target.value })
    }

    const handleSubmit = async () => {
        try {
            if (formState.codeno && formState.recipe) {
                const response = await postMethod(API_URL.POST_COUSTOM_ORDER, formState, false, false)
                if (response.status === API_STATUS_CODE.SUCCESS) {
                    getMachineState()  // Initial API call as a fallback
                } else {
                    alert("Invalid Machine Code or Recipe")
                }
            }
        } catch (e) {
            console.log(e)
        }
    }

    const getMachineState = async () => {
        try {
            const response = await getMethod(API_URL.GET_MACHINE_STATE + "/allState", false, false)
            if (response && response.data) {
                setStatusList((prevStatusList) => [...prevStatusList, JSON.stringify(response.data)])
            } else {
                console.warn("No data found in response")
            }
        } catch (error) {
            console.log("Error fetching machine state:", error)
        }
    }

    useEffect(() => {
        // Establish WebSocket connection
        const ws = new WebSocket(WEBSOCKET_GATEWAY)

        ws.onopen = () => {
            console.log("WebSocket connected")
        }

        ws.onmessage = (event) => {
            try {
                const message = JSON.parse(event.data)
                setStatusList((prevStatusList) => [...prevStatusList, JSON.stringify(message)])
            } catch (error) {
                console.log("Error parsing WebSocket message:", error)
            }
        }

        ws.onerror = (error) => {
            console.error("WebSocket error:", error)
        }

        ws.onclose = () => {
            console.log("WebSocket disconnected")
        }

        // Polling as a fallback (optional)
        const intervalId = setInterval(getMachineState, 10000) // Reduced frequency to 10 sec

        return () => {
            ws.close()
            clearInterval(intervalId)
        }
    }, [])

    return (
        <>
            <Outlet />
            <Page title="Clover Carte | Custom-Order">
                <div
                    style={{
                        height: "100vh",
                        width: "100%",
                        backgroundColor: "rgba(0,0,0,0.5)",
                        zIndex: 1000000,
                        position: "fixed",
                        top: 0,
                        left: 0,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                >
                    <div style={{ overflowY: 'scroll', height: '100vh', width: '100%' }} className="card p-2">
                        <h4 className="text-secondary">Create Custom Order</h4>
                        <div className="row p-1">
                            <div className="col-md-4 my-3">
                                <label className='mb-2 fw-bold'>Machine Code</label>
                                <input
                                    name="codeno"
                                    value={formState.codeno || ''}
                                    onChange={handleChange}
                                    type="number"
                                    className="form-control"
                                    required
                                    autoFocus
                                />
                            </div>
                            <div className="col-md-4 my-3">
                                <label className='mb-2 fw-bold'>Order No</label>
                                <input
                                    name="orderno"
                                    value={formState.orderno || ''}
                                    onChange={handleChange}
                                    type="text"
                                    className="form-control"
                                    required
                                />
                            </div>
                            <div className="col-md-4 my-3">
                                <label className='mb-2 fw-bold'>Recipe Code</label>
                                <input
                                    name="recipe"
                                    value={formState.recipe || ''}
                                    onChange={handleChange}
                                    type="text"
                                    className="form-control"
                                    required
                                />
                            </div>
                        </div>
                        <div className="d-flex w-100 justify-content-end">
                            <button onClick={handleSubmit} className="btn btn-success m-1">Submit</button>
                        </div>
                        <div className='list-container'>
                            <Typography variant='h5'>Machine Status</Typography>
                            <ol reversed>
                                {statusList?.slice()?.reverse()?.map((status, index) => (
                                    <li className='fw-bold' key={index}>{status}</li>
                                ))}
                            </ol>
                        </div>
                    </div>
                </div>
            </Page>
        </>
    )
}

export default CustomOrder

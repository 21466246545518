import { useState, useEffect, useCallback, useRef } from 'react';
import { API_URL } from '../constants/url.constant';
import { API_STATUS_CODE } from '../constants/content.constant';
import { useSelector } from 'react-redux';
import { getMethod } from './api';





const decodeStatusString = (statusString, orderData, responseCount, setOrderPreparing) => {
    const orderNumberInStatus = statusString.split('@')[1];
    const pendingOrders = statusString.split('~')[0];

    for (const item of orderData.items) {
        for (const foodStatus of item.food_status) {
            if (foodStatus.snum === orderNumberInStatus) {
                const conveyorPosition = statusString[2];
                const preparingOrder = statusString[12] !== 'Z';
                const positionB = statusString[13] === 'b';

                if (conveyorPosition == 9) {
                    if (setOrderPreparing.current) {
                        return { message: `Order is completed and ready, Please pick it up and Enjoy`, isLoading: false, newOrderNumber: false,token:orderNumberInStatus,showRateing:true,bar:"rd" };
                    } else {
                        return { message: `Order is completed and ready, Please pick it up and Enjoy`, isLoading: false, newOrderNumber: false,token:orderNumberInStatus,showRateing:true,bar:"rd"};
                    }
                } else {
                    if (positionB && preparingOrder) {
                        setOrderPreparing.current = true;
                        return { message: `Machine making the order and order number is being displayed on Machine`, isLoading: false, newOrderNumber: true,token:orderNumberInStatus,bar:"OK" };
                    } else if (responseCount.current < 3) {
                        responseCount.current += 1;
                    } else {
                        setOrderPreparing.current = false;
                        return { message: `Order Number ${orderNumberInStatus} is declined by the machine.`, isLoading: true, newOrderNumber: true,token:orderNumberInStatus,bar:"fl" };
                    }
                }
            }
        }
    }

    return { message: '', isLoading: true, newOrderNumber: true };
};

const useOrderStatus = (orderData) => {
    const [status, setStatus] = useState({ message: '',token:'', isLoading: true,showRateing:false });
    const orderPreparing = useRef(false);
    const currentOrderNumber = useRef(null);
    const responseCount = useRef(0);
    const data = useSelector(state => state.machineSlice.machine);
    const codeno = data ? data.codeno : '';
    const fetchMachineStatus = useCallback(async () => {
        try {
            const response = await getMethod(API_URL.GET_MACHINE_STATE + "/" + codeno);
            if (response?.status === API_STATUS_CODE.SUCCESS) {
                // const statusString = response.data;
                const statusString = '4~90110010009b00U@161';
                console.log('Status String:', statusString);
                const { message, isLoading, newOrderNumber,token,showRateing,bar } = decodeStatusString(statusString, orderData, responseCount, orderPreparing);
               
                if (message.includes('ready')) {
                    responseCount.current = 0;  // Reset counter when order is ready
                    orderPreparing.current = false;
                }

                if (newOrderNumber) {
                    currentOrderNumber.current = statusString.split('@')[1]; // Update current order number
                }

                setStatus({ message, isLoading,token,showRateing,bar });
            } else {
                console.error('Unexpected response status:', response?.status);
            }
        } catch (error) {
            console.error('Error fetching machine state:', error);
        }
    }, [orderData]);

    useEffect(() => {
        const intervalId = setInterval(fetchMachineStatus, 5000);
        return () => clearInterval(intervalId);
    }, [fetchMachineStatus]);

    return status;
};


export default useOrderStatus;
